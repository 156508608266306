// Bootstrap setup
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Config
@import "variables";

// Bootstrap components
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
@import "./ui/form-transaction";
@import "./ui/charging";
@import "./ui/default";
@import "./ui/form-email";
@import "./ui/charging-info";